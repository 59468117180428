import * as React from "react";
import { useCallback } from "react";
import { Root } from "./Form.styles";

interface IProps {
    readonly onSubmit?: () => void;
}

export const Form: React.FC<React.PropsWithChildren<IProps>> = ({ children, onSubmit }) => {
    const onFormSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            onSubmit?.();
        },
        [onSubmit]
    );
    return <Root onSubmit={onFormSubmit}>{children}</Root>;
};
