import { Dispatch, SetStateAction, useCallback, useState } from "react";

type ReturnTuple = [string, Dispatch<SetStateAction<string>>, (_: unknown, newValue: string) => void];

export const useInputState = (initialValue: string): ReturnTuple => {
    const [state, setState] = useState(initialValue);
    const onInputChanged = useCallback(
        (_: unknown, newValue: string) => {
            const updatedValue = newValue === undefined ? "" : newValue;
            setState(updatedValue);
        },
        [setState]
    );
    return [state, setState, onInputChanged];
};
