import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { SessionStorageKey } from "../../../services/Constants/SessionStorageKey";
import { H4 } from "../../atoms/H4/H4";

export const CompleteOrderPrompt: React.FC = () => {
    const [t] = useTranslation();
    const [rawOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);
    if (rawOrderDetails !== null) {
        return <H4>{t("completeOrderPrompt.completeOrder")}</H4>;
    }
    return null;
};
