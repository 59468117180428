import { AxiosRequestConfig, AxiosProgressEvent } from "axios";
import { telemetry } from "../services/Telemetry/Telemetry";

interface IConfigOptions {
    readonly signal?: AbortSignal | undefined;
    readonly type?: "multipart/form-data" | "application/json";
    readonly onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export const getAxiosRequestConfig = (token: string | null, options: IConfigOptions = {}): AxiosRequestConfig => {
    const defaultHeaders = { "x-clientId": telemetry.ClientId, "x-initId": telemetry.InitId };
    const signal = options?.signal;
    const type = options?.type;
    const onUploadProgress = options?.onUploadProgress;
    const config: AxiosRequestConfig = {
        headers: {
            ...defaultHeaders,
            ...(token !== null && { Authorization: `Bearer ${token}` }),
            ...(type !== undefined && { "Content-Type": type }),
        },
        ...(onUploadProgress !== undefined && { onUploadProgress }),
        ...(signal !== undefined && { signal }),
    };
    return config;
};
